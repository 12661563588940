export default defineNuxtRouteMiddleware(async (to, from) => {
  const { isLoggedIn } = useAuth()
  if (!isLoggedIn.value) {
    if (to.query?.verified === '1') {
      useToasty().success('Your email has been verified. You can now login.')
    }

    localStorage.removeItem('token')

    return navigateTo('/login', { replace: true })
  }
})
